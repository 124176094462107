/**
 * Reference from this https://phrase.com/blog/posts/step-step-guide-javascript-localization/
 */

// We can have as many locales here as we want, and use any locales we want. We have English and German as locales here as examples.

// data-i18n-key="myName"
// data-i18n-opt='{"username": "crushftp"}' Note: It's used to pass dynamic value/variables
{
  /* <h1 class="text-accentMain" data-i18n-key="myName" data-i18n-opt='{"username": "crushftp"}'>My name is {username}</h1> */
}

import { _$ } from "/WebInterface/new-ui/assets/js/utils/dom.js";

// The locale our app first shows
const defaultLocale = localStorage.getItem("selected-language") || "en";
// The active locale
let locale;
// Gets filled with active locale translations
let translations = {};
// When the page content is ready...
document.addEventListener("DOMContentLoaded", async () => {
  // Translate the page to the default locale

  document.documentElement.setAttribute("lang", defaultLocale);

  setLocale(defaultLocale);
  bindLocaleSwitcher(defaultLocale);
});

const fetchTranslationsFor = async (newLocale) => {
  const response = await utils.importModule(
    `/WebInterface/new-ui/localizations/${newLocale}.js`
  );

  return response.default;
};
// Load translations for the given locale and translate
// the page to this locale
const bindLocaleSwitcher = (initialValue) => {
  const switcher = _$("[data-i18n-switcher]");
  switcher.val(initialValue);

  switcher.on("change", (e) => {
    // Set the locale to the selected option[value]
    switcher.val(e.target.value);
    setLocale(e.target.value);
    localStorage.setItem("selected-language", e.target.value);
  });
};

// Replace the inner text of the given HTML element
// with the translation in the active locale,
// corresponding to the element's data-i18n-key
const translateElement = (element) => {
  const key = element.getAttribute("data-i18n-key");
  const options = JSON.parse(element.getAttribute("data-i18n-opt")) || {};
  const attrToUpdate = element.getAttribute("data-i18n-attr-to-update");
  const defValue = element.getAttribute("data-i18n-value");

  if (element.getAttribute("data-i18n-allow-html")) {
    if (attrToUpdate) {
      element.setAttribute(
        attrToUpdate,
        utils.sanitizePure(translate(key, options, defValue))
      );
    } else {
      element.innerHTML = utils.sanitizePure(translate(key, options, defValue));
    }
  } else {
    if (attrToUpdate) {
      element.setAttribute(attrToUpdate, translate(key, options, defValue));
    } else {
      element.innerText = translate(key, options, defValue);
    }
  }
};

const translate = (key, interpolations = {}, defVal) => {
  const value = translations[key] || defVal;
  return value ? interpolate(value, interpolations) : defVal || key;
};

// data-i18n-key attribute with the translation corresponding
// to its data-i18n-key
const translatePage = () => {
  document.querySelectorAll("[data-i18n-key]").forEach(translateElement);
};

const setLocale = async (newLocale) => {
  if (newLocale === locale) return;

  document.documentElement.setAttribute("lang", newLocale);

  const newTranslations = await fetchTranslationsFor(newLocale);

  const uploadIframe = document.querySelector("#newFileUploadIframe");
  uploadIframe?.contentWindow?.postMessage({
    key: "language",
    value: { newTranslations, newLocale },
  });

  const manageShareIframe = document.querySelector("#cftp_iframeManageShares");
  manageShareIframe?.contentWindow?.postMessage({
    key: "language",
    value: newLocale,
  });

  locale = newLocale;
  translations = newTranslations;
  translatePage();
};

// Convert a message like "Hello, {name}" to "Hello, World"
// given the interpolations object {name: "World"}
const interpolate = (message, interpolations) => {
  return Object.keys(interpolations).reduce((interpolated, key) => {
    return interpolated.replace(
      new RegExp(`{\s*${key}\s*}`, "g"),
      interpolations[key]
    );
  }, message);
};

export const t = (key, interpolations = {}, defVal) => {
  const value = translations[key] || defVal;
  return value ? interpolate(value, interpolations) : defVal || key;
};

export const setLanguage = (language = "en") => {
  document.documentElement.setAttribute("lang", language);

  setLocale(language);
  const switcher = document.querySelector("[data-i18n-switcher]");
  switcher.value = language;
};

export const translateComponent = (component) => {
  if (!component) return;
  component.querySelectorAll("[data-i18n-key]").forEach(translateElement);
};
